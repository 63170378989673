<template>
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script>
export default {
    name: "Preloader"
}
</script>

<style scoped>
.spinner-border{
    margin: 50px auto;
    display: flex;
}
</style>
