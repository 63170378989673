<template>
    <div class="container profile-container">
        <Breads :links="breadLinks"/>
        <div class="title">
            {{$t('Profile')}}
        </div>
        <div>
            <form class="form" @submit.prevent="submitForm" ref="profileForm">
                <div class="form-title">{{$t('Making changes to personal data')}}</div>
                <Preloader v-if="preloader"></Preloader>
                <div class="inputs-container" v-if="!preloader">
                    <div class="left-block">
                        <InputComponent
                            name="first_name"
                            class="input-component"
                            :placeholder="$t('Name ')"
                            :showLabel="true"
                            :error="errors.first_name"
                            v-model="userData.first_name"
                        />
                        <InputComponent
                            name="login"
                            class="input-component"
                            :placeholder="$t('login')"
                            :showLabel="true"
                            :error="errors.login"
                            v-model="userData.login"
                        />
                        <InputPhoneComponent
                            name="phone"
                            @input="changePhone"
                            @validatePhone="validatePhone"
                            :error="errors.phone"
                            :showLabel="true"
                            class="input-component"
                            :placeholder="$t('Phone')"
                            v-model="userData.phone"
                        />
                    </div>
                    <div class="right-block">
                        <InputComponent
                            name="email"
                            class="input-component"
                            placeholder="E-mail"
                            :showLabel="true"
                            :error="errors.email"
                            v-model="userData.email"
                        />
                        <InputComponent
                            name="password"
                            class="input-component"
                            :placeholder="$t('Password')"
                            :showLabel="true"
                            :error="errors.password"
                            v-model="userData.password"
                            :type="type"
                        />
                        <button class="show-password material-icons" @click.prevent="togglePassword">{{ type !== 'password' ? 'visibility_off' : 'visibility' }}</button>
                        <SelectComponent
                            class="input-component"
                            name="language"
                            v-model="userData.locale"
                            :value="userData.locale"
                            :options="languageOptions"
                            :showLabel="true"
                            :placeholder="$t('Language')"
                            :error="errors.language ? errors.language : ''"
                            @input="changeLanguage"
                        />
                    </div>
                </div>
                <div class="cinema-data" v-if="userData.network">
                    <div class="network-item"><span>{{$t('Network of cinemas')}}:</span> {{userData.network.name}}</div>
                    <div class="network-item" v-if="allCinemas"><span>{{$t('Network cinemas')}}:</span> {{allCinemas}}</div>
                </div>
                <div class="btn-container" v-if="!preloader">
                    <button type="submit" class="save">{{$t('Save')}}</button>
                    <router-link :to="homePage">
                        <button type="button" class="clear">{{$t('Cancel')}}</button>
                    </router-link>
                </div>
                <div class="btn-exit" @click="logout">
                    <button type="button" class="exit">
                        <span class="material-icons">logout</span>
                        {{$t('Log out of the account')}}
                    </button>
                </div>
                <transition name="fade-modal">
                     <ModalMini :action="removeModalSuccess" @close="userData.password = ''; isMiniModalVisible = false"
                        v-if="isMiniModalVisible" :title="$t('Warning')" :subtitle="$t('Warning Text')"/>
                </transition>
            </form>
        </div>
    </div>
</template>

<script>
import Breads from "../../components/Breads";
import FormComponent from "../../components/ui/forms/FormComponent";
import InputComponent from "../../components/ui/forms/InputComponent";
import InputPhoneComponent from "../../components/ui/forms/InputPhoneComponent";
import SelectComponent from "../../components/ui/forms/SelectComponent";
import Preloader from "../../components/ui/Preloader";
import ModalMini from "@/components/ui/ModalMini"
import ButtonComponent from "@/components/ui/ButtonComponent"


export default {
    name: "Profile",
    components:{
        Breads,
        FormComponent,
        InputComponent,
        InputPhoneComponent,
        SelectComponent,
        Preloader,
        ModalMini,
        ButtonComponent
    },
    data () {
        return {
            breadLinks: [
                {
                    label: this.$t('Profile'),
                    to: {name: 'Profile'}
                }
            ],
            userData: {},
            language: null,
            errors: {},
            homePage: '/',
            type: 'password',
            languageOptions: [
                {id: 1, label: 'Українська'},
                {id: 2, label: 'English'}
            ],
            isPhoneDirty: false,
            cinemasList: [],
            allCinemas: '',
            preloader: true,
            isMiniModalVisible: false
        }
    },
    methods:{
        async getUserData(){
            this.preloader = true
            let resp = await this.api.user.getUserData().catch(this.handleError)
            this.userData = resp
            this.preloader = false
        },
        togglePassword() {
            this.type = this.type === 'password' ? 'text' : 'password';
        },
        removeModalSuccess() {
            this.isMiniModalVisible = false
            this.submitForm(null)
        },
        changePhone(v) {
            if (this.isPhoneDirty == false && v.length) {
                this.isPhoneDirty = !this.isPhoneDirty
                this.errors.phone = this.$t('Phone Error')
            }
        },
        changeLanguage(v){
            if (v === 2){
                this.$store.dispatch('setLocale', 'en');
            } else if (v === 1){
                this.$store.dispatch('setLocale', 'ua');
            }
        },
        validatePhone(phoneObject) {
            if (phoneObject.valid === true) {
                this.userData.phone = phoneObject.number;
                this.errors.phone = ''
            }
            else if (phoneObject.valid === false) {
                this.userData.phone = '';
                this.errors.phone = this.$t('Phone Error')
            }
        },
        async submitForm(m = null){
            if (m) {
                if (this.userData.password?.length) {
                    this.isMiniModalVisible = true
                    return false
                }
            }
            let resp = await this.api.authentication.updateUserData(this.userData).catch(this.handleError)
            if (this.handleError({}) == 1) {
                return false
            }
            if (resp){
                this.toast.success(this.$t('Data saved successfully'))
                this.errors = {}
                this.reloadPage()
            }
        },
        handleError(resp) {
            if (this.errors.phone?.length) {
                this.errors.phone = this.$t('Phone Error')
                return 1
            }
            if (resp.statusCode === 422) {
                this.errors = resp.messages
            }
        },
        async logout(){
            localStorage.removeItem("notification")
            let resp = await this.api.authentication.logout()
            if (resp) {
                window.location.reload();
            }
        },
        async getCinemasList(){
            let resp = await this.api.cinema.cinemaWithoutPagination().catch(this.handleError)
            resp.forEach(cinema => {
                if (cinema.network_id === this.userData.network_id){
                    this.cinemasList.push(cinema.name)
                }
                this.allCinemas = this.cinemasList.join(', ')
            })
        },
        checkLanguage(){
            if (this.$store.getters.getAppLocale === 'ua'){
                this.userData.locale = 1
            } else {
                this.userData.locale = 2
            }
        },
        reloadPage(){
            window.location.reload()
        }
    },
    created() {
        this.getUserData()
        this.getCinemasList()
        this.checkLanguage()

        if (this.role.getRole() === 1){
            this.homePage = '/admin/users'
        } else if (this.role.getRole() === 2){
            this.homePage = '/statistics'
        } else if (this.role.getRole() === 3){
            this.homePage = '/home/distributors'
        } else if (this.role.getRole() === 4){
            this.homePage = '/home/network'
        } else if (this.role.getRole() === 5){
            this.homePage = '/home/cinema'
        }
    }
}
</script>

<style scoped lang="scss">
    .profile-container{
        .title{
            font-size: 30px;
            font-weight: 600;
            color: #141213;
            margin-top: 8px;
            margin-bottom: 10px;
        }
    }
    .form{
        background-color: white;
        min-height: 80vh;
        padding: 60px 112px;
        @media (max-width: 768px) {
            padding: 30px;
        }
        .form-title{
            display: flex;
            justify-content: center;
            font-size: 24px;
            font-weight: 600;
            color: #141213;
        }
        .inputs-container{
            display: flex;
            width: 100%;
            border-bottom: 1px solid #edf3ff;
            border-radius: 2px;
            padding-bottom: 30px;
            @media (max-width: 768px){
                flex-direction: column;
            }
            .left-block, .right-block{
                width: 50%;
                position: relative;
                @media (max-width: 768px){
                    width: 100%;
                }
            }
            .left-block{
                margin-right: 30px;
            }
            .input-component{
                margin-top: 25px;
            }
        }
        .show-password {
            position: absolute;
            border: 0;
            background-color: transparent;
            color: $color_darkblue;
            right: 5px;
            bottom: 115px;
            cursor: pointer;
        }
        .cinema-data{
            margin-top: 35px;
            .network-item{
                font-size: 18px;
                font-weight: 600;
                color: #141213;
                margin-bottom: 20px;
                span{
                    font-size: 16px;
                    color: #646e78;
                    min-width: 147px;
                    font-weight: normal;
                    display: inline-block;
                }
            }
        }
        .btn-container{
            display: flex;
            justify-content: center;
            margin-top: 52px;
            .save{
                width: 173px;
                height: 45px;
                border-radius: 2px;
                color: white;
                text-transform: uppercase;
                background-color: #2d5ca6;
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                &:hover{
                    box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                    color: $color_lightpink;
                }
            }
            .clear{
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                width: 172px;
                height: 45px;
                margin: 0 0 0 15px;
                padding: 16px 0 15px;
                border-radius: 2px;
                border: solid 1px $color_darkblue;
                background-color: white;
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 2px;
                color: #152847;
                a{
                    color: #141213;
                }
            }
        }
        .btn-exit{
            display: flex;
            justify-content: center;
            margin-top: 137px;
            .exit{
                border: none;
                background-color: white;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 2px;
                color: #141213;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    color: $color_darkblue;
                    font-size: 20px;
                    margin-right: 17px;
                }
            }
        }
    }

    .modal{
        transition: background-color .2s linear, backdrop-filter .2s linear;
        min-height: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        background-color: #edf3ffb3;
        display: flex !important;
        align-items: center;
        justify-content: center;

        &.fade-modal-enter, &.fade-modal-leave-to {
            background-color: transparent;

            .modal__content {
                transform: translateY(-200px);
                opacity: 0;
            }
        }
    }
</style>
