<template>
    <div class="modal" tabindex="-1" aria-labelledby="modalMini" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="typography typography--h4 modal-title" id="modalMini">{{ title }}</h4>
            <button type="button" class="btn-close" @click="$emit('close')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <p class="typography typography--paragraph">{{ subtitle }}</p>
          </div>
          <div class="modal-footer">
            <ButtonComponent type="button" theme="primary" v-if="!forDelete" @click="action" :caption="$t('Yes')" />
            <ButtonComponent type="button" theme="primary" v-else-if="forDelete" @click="deleteItem" :caption="$t('Yes')" />
            <ButtonComponent type="button" theme="bordered" @click="$emit('close')" :caption="$t('No')" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent"
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        action: {
            required: false,
            type: Function
        },
        forDelete:{
            required: false,
            type: Boolean,
            default: false,
        }
    },
    methods:{
        deleteItem(){
            this.$emit('deleteItem')
        }
    },

    components: {
        ButtonComponent
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
    }
    .modal-content {
        border: none;
    }

    .modal-header {
        border-bottom: none;
    }
    .modal-footer{
        justify-content: center;
    }
</style>
